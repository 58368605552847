.work_detalle{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.articuloTrabajoDetalle {
    max-width: 90%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional para mejor visualización */
    border: 2px solid black;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.8);
    margin: 5vw;
}

#Acceder{
    border: 1px solid black;
    border-radius: 4px;
    padding: 0.3vw;
    color: whitesmoke;
    background: black;
    text-decoration: none;
    
}