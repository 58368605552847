.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black; /* O el color de fondo que prefieras */
    color: white; /* O el color de texto que prefieras */
    z-index: 1000; /* Asegúrate de que está encima de todo lo demás */
}

.hidden {
    display: none;
}

.logo_animacion{
    width: 8vh;
    height: auto;
    animation: combinedAnimation 4s linear infinite;
    filter: drop-shadow(0px 7px 21px #ffffff);
}

@keyframes combinedAnimation {
    0% {
        transform: scale(0.1);
    }
    50% {
        transform: scale(1); 
    }
    100% {
        transform: scale(2);
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

.typewriter{
    padding: 0 40px 0 40px;
}

.typewriter h1 {
    font-size: 8vw;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    animation: blink 0.75s step-end infinite;
    text-shadow: 5px 5px 15px rgb(255, 255, 255);
}

@media(max-width:960px){
    
.typewriter h1 {
    font-size: 8vw;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    animation: blink 0.75s step-end infinite;
    text-shadow: 5px 5px 15px rgb(255, 255, 255);
}
}