.contentPresskit{
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentEntradas{
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.works {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* Crea dos columnas de igual tamaño */
    gap: 60px;  /* Espacio entre los elementos */
    width: 70%; 
}

.works_entradas{
    display: grid;
    grid-template-columns: repeat(1, 1fr);  /* Crea dos columnas de igual tamaño */
    gap: 60px;  /* Espacio entre los elementos */
    width: 70%; 
}

article{
    background: #d9d9d9;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.8);
    border-radius: 8px;
}

.mask{
    border-radius: 8px;
}

.infoWorks{
    padding: 0.5vw;
    padding-bottom: 1vw;
}

@media (max-width: 700px) {
    .works {
      grid-template-columns: 1fr; /* Solo una columna en pantallas pequeñas */
      width: 100%; 
    }

    .works_entradas{
        width: 100%; 
    }
}

@media (max-width: 1000px) {
    .works {
      width: 100%; 
    }
    .works_entradas{
        width: 100%; 
    }
}