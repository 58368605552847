.iconoReact{
    height: 0.6em;
}
.iconos{
    height: 0.5em;
}

.columna1Bienvenido, .columna2Bienvenido, .columna2Versatilidad{
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.8);
  /* Desplazamiento horizontal, desplazamiento vertical, desenfoque, color */
  border-radius: 8px; /* Opcional: bordes redondeados */
}

.presentacion_video{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Hace que el contenedor ocupe toda la altura de la pantalla */
  overflow: hidden; /* Oculta cualquier contenido desbordado */
}

.contenedorPresentacion_video{
  width: 100%;
  height: 100%;
  position: relative;
}

.videoPresentacion{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que el video cubra todo el contenedor */
  object-position: center; /* Centra el video dentro del contenedor */
  border-radius: 0px;
}

.presentacion_sinfondo{
  background: black;
  display: flex;
  flex-direction: column;
}

.contenedorPresentacion{
  justify-content: space-around;
}

/*                 hacer que gire el icono React                     */
  
  @keyframes rotar{
    from{
      transform: rotateZ(180deg);
    }to{
      transform: rotateZ(-180deg);
    }
  }

@media (max-width:700px){
    .contenedorPresentacion h1{
        font-size: 2vh;
        padding-top: 15vh;
    }
    .contenedorPresentacion h2{
        font-size: 2vh;
        padding-top: 15vh;
    }

    .iconoReact{
        height: 0.4em;
    }
    .iconos{
        height: 0.4em;
    }

    .bienvenido {
        flex-direction: column; /* Cambia la dirección del flex container a vertical */
        align-items: center; /* Centra los elementos horizontalmente */
        background: #ce9e10;
    }
    
    .columna1Bienvenido {
        width: 100%; /* Ocupa el ancho completo del contenedor padre */
        margin-bottom: 20px; /* Agrega espacio entre columna1Bienvenido y columna2Bienvenido */
        margin: 20px;
    }
    
    .columna2Bienvenido,
     .columna2Versatilidad {
        min-width: 100%; /* Ocupa el ancho completo del contenedor padre */
    
      }
}
