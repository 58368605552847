
.presentacion{
    background-image: url(../../../public/img/Fondo.jpg);
    background-size: cover; /*Adapta la img de fondo a la pantalla cubriendo*/
    background-position: center; /*Posiciona la img de fondo en el medio*/
    height: 100vh;
    width: 100%;
    
  }
  
  .iconosLenguajes{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  
    display: flex;
  
    width: auto;
    padding-top: 10px;
  }
  
  .iconosLenguajes .iconos{
    font-size: 3rem!important;
    color:#BA9323;
  }
  
  .contenedorIconoReact{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
  
    padding-top: 10px;
  }
  
  .iconoReact{
    font-size: 5rem!important;
    color: #ce9e10;
    animation: rotar 3s linear infinite;
    filter: drop-shadow(0px 7px 21px #ffffff);
    
  }
  
  .contenedorPresentacion{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .contenedorPresentacion h1, h2{
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .bienvenido{
    display: flex;
    justify-content: space-around;
  
    min-height: 500px;
    margin: 30px;
  
  }
  
  .columna1Bienvenido{
    padding: 20px;
    width: 30%;
    background: #d9d9d9;
  }
  
  .columna2Bienvenido{
    min-width: 50%;
    background: url(../../../public/img/webDesignerHorizontal.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
  }
  
  .columna2Versatilidad{
    min-width: 50%;
    background: url(../../../public/img/codigo.JPG) no-repeat;
    background-size: cover;
    background-position: center;
  
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
  }
  