#imgDescargar{
  width: 1.6vw;
}

a{
    color:black;
  }
  
  a:hover{
    color:#BA9323;
  }
  
  .layout{
    width: 100%;
    margin: 0 auto;
  }
  
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    border-bottom: 2px solid black;
  }
  
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  
  .menu-button {
    display: none; /* Oculta el botón por defecto */
    font-size: 1.5rem;
    cursor: pointer;
    background: none;
    border: none;
  }
  
  .header .logo{
    display: flex;
  }
  
  .header .logo h3{
    padding-left: 10px;
    font-weight: 900;
    text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
    font-size: 2vw;
  }
  
  .header .logo b{
    color: #BA9323;
    font-weight: 900;
  }
  
  .header nav{
    flex-grow: 1;
  }
  
  .header nav ul{
    list-style: none;
    display: flex;
  }
  
  .header li{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4vw;
    padding-right: 2vw;
  }
  
  .header li a{
    color: black;
    text-decoration: none;
    transition: all 300ms;
  }
  
  .header a:hover{
    color: #BA9323;
  
  }
  
  .header .active{
    color: #BA9323;
  }


  @media (max-width: 700px) {    
    #imgDescargar{
      width: 1.8vw;
    }
      nav ul {
        display: none; /* Oculta la lista de navegación por defecto */
        flex-direction: column;

        top: 60px;
        left: 0;
        background: white;
        width: 100%;
      }
    
      .open li {
        display: flex; /* Muestra la lista cuando el menú está abierto */
      }
    
      .close li{
        display: none; /* Oculta la lista de navegación cuando el estado menuOpen es false */
      }
    
      .menu-button {
        display: block; /* Muestra el botón en pantallas más pequeñas */
      }
    
      .menu-open{
        display: flex;  

      }
    
      .open{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .header nav ul{
        display: flex;
        text-align: center;
        align-items:end;
      }
    
      .iconoReact{
        margin-top: 10%;
      }

      .header li{
        padding-bottom: 1.5vw;
        font-size: 2.5vw;
      }

      .header .logo h3{
        padding-left: 10px;
        font-weight: 900;
        text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
        font-size: 3.5vw;
      }
    
  }