.contentFilms {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.articleVideos {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.works_films {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* Crea dos columnas */
    gap: 60px;  /* Espacio entre los elementos */
    width: 100%;
    max-width: 1200px;  /* Limita el ancho máximo del grid */
}

video {
    width: 100%;  /* Asegura que el video ocupe todo el ancho disponible */
    height: 100vh;  /* Ocupa toda la altura de la ventana gráfica */
    object-fit: cover;  /* Ajusta el contenido del video para cubrir el contenedor */
    border-radius: 8px;
}

@media (max-width: 1400px) {
    .contentFilms {
        padding: 0px;
        width: 100%;
    }

    .works_films {
        grid-template-columns: 1fr; /* Solo una columna en pantallas pequeñas */
        width: 90%; /* Ajusta el ancho al 100% */
    }

    video {
        height: auto; /* Ajusta la altura automáticamente */
        max-height: 100vh;  /* No permita que el video sea más alto que la ventana gráfica */
        width: 100%; /* Asegura que el video ocupe todo el ancho disponible */
    }

    .articleVideos {
        width: 100%; /* Asegura que el artículo ocupe todo el ancho disponible */
    }
}