
.cv{
  margin-top: 4vh;
  width: auto;
    display: grid;
    grid-template-columns: 0.8fr 0.8fr;
    width: 100%;
    gap: 2vw;
}

  .columna1CvSinfoto{
    background: #d9d9d9;
    width: 190%;
    margin: 2vw;
    box-shadow: 5px 5px 15px black;
    border-radius: 8px;
    padding: 1vw;
  }
  
  .columna1Cv{
    background: #d9d9d9;
    width: 90%;
    margin: 2vw;
    box-shadow: 5px 5px 15px black;
    border-radius: 8px;
    padding: 1vw;
  }
  
  .columna2Cv{
    background: #d9d9d9;
    width: 90%;
    height: auto;
    display: block;
    margin: 2vw;
    box-shadow: 5px 5px 15px black;
    border-radius: 8px;
  }
  .ilustracion_cv{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 8px;
  }
  
  .cv .columna1Cv .textoCv a{
    border: black 2px solid;
    background: black;
    color: #ffffff;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    text-decoration: none;
  
  }
  
  #titCv{
    margin-bottom: 30px;
    text-align: center;
  }
  
  .textoCv p{
    margin-bottom: 30px;
  }
  
  .textoCv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
  }
  
  .divBtnCv{
    justify-content: center;
  }


  @media(max-width: 950px){
    .cv{
      grid-template-columns: 0.85fr;
      justify-content: center;
    }

    .columna1Cv{
      width: 95%;
      padding: 2vw;
      margin: 1vw;
    }
    .columna2Cv{
      width: 95%;
      border-radius: 50%;
    }

    .columna1CvSinfoto{
      width: 95%;
    }

    .ilustracion_cv{
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 8px;
    }
  }