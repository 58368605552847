#tituloPotafolio{
  font-size: 6vh;
  text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
}

.divContenedorPortafolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contentPortafolio {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
  }

  .contentPortafolio h1{
    margin-bottom: 4vh;
  }
  
  .works {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* Crea dos columnas de igual tamaño */
    gap: 60px;  /* Espacio entre los elementos */
    
  }
  
  .articuloTrabajo {
    max-width: 500px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional para mejor visualización */
    border: 2px solid black;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.8);
  }
  
  .mask {
    overflow: hidden;
  }
  
  .mask img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .mask:hover img {
    transform: scale(1.05);
  }

  @media (max-width: 700px) {
    .works {
      grid-template-columns: 1fr; /* Solo una columna en pantallas pequeñas */
    }
  }